<template>
  <v-tabs-items v-model="tab">
    <v-tab-item v-for="tab in tabs" :key="tab.value">
      <MemberList
        ref="scroll"
        class="cr-scroll"
        :key="tab.value"
        v-bind="$props"
        v-on="$listeners"
      />
    </v-tab-item>
  </v-tabs-items>
</template>

<style lang="scss" scoped>
.v-tabs-items {
  height: 420px;
  width: 552px;
  margin-top: -1px;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
</style>

<script>
import MemberList from "./list";

export default {
  components: { MemberList },
  props: {
    tab: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array,
      default: () => []
    },
    selectedRows: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    tab(tab) {
      setTimeout(() => {
        if (!this.$refs.scroll[tab]) return;
        this.$refs.scroll[tab].$el.scrollTop = 0;
      }, 0);
    }
  }
};
</script>
